<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="promocodes.selected"
      :items="promocodes.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
    >
      <!-- Название -->
      <template #[`item.code`]="{item: promocode}">
        <span
          class="main-table-link"
          @click="editPromocode(promocode)"
        >
          {{ promocode.code }}
        </span>
      </template>

      <!-- ДЕЙСТВУЕТ ДО -->
      <template #[`item.dateLimit`]="{item: promocode}">
        {{ promocode.dateLimit ? moment(promocode.dateLimit).format('DD/MM/YYYY HH:mm') : '' }}
      </template>

      <!-- ДАТА СОЗДАНИЯ -->
      <template #[`item.created_at`]="{item: promocode}">
        {{ promocode.created_at ? moment(promocode.created_at).format('DD/MM/YYYY HH:mm') : 'Неизвестно' }}
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PromocodesMainTable',

  data() {
    return {
      moment,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'promocodes/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'promocodes/pagination',
    }),
    ...mapFields('promocodes', ['promocodes']),

    cols() {
      const cols = [];

      cols.push({ text: 'Код', value: 'code' });
      cols.push({ text: 'Почта', value: 'email' });
      cols.push({ text: 'Размер скидки', value: 'discount' });
      cols.push({ text: 'Действует до', value: 'dateLimit' });
      cols.push({ text: 'Лимит кол-ва', value: 'quantityLimit' });
      cols.push({ text: 'Автор', value: 'authorUsername' });
      cols.push({ text: 'Дата создания', value: 'created_at' });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    async editPromocode(promocode) {
      await this.$store.dispatch('promocodes/setPromocodeForMainDialog', promocode);
      this.$store.dispatch('promocodes/openDialog', 'mainDialog');
    },

    updateFooterOptions(options) {
      this.$store.dispatch('promocodes/loadPromocodes', { page: options.page, itemsPerPage: options.itemsPerPage });
    },
  },
};
</script>
