<template>
  <v-dialog
    :value="dialogs.mainDialog"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить промокод
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-row>
          <!-- КОД -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.promocode.code"
              label="Код"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>

          <!-- ПОЧТА -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.promocode.email"
              label="Почта"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>

          <!-- РАЗМЕР СКИДКИ -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.promocode.discount"
              label="Размер скидки"
              dense
              outlined
              :spellcheck="false"
              type="number"
              :rules="rules.discount"
              hide-details="auto"
            />
          </v-col>

          <!-- ЛИМИТ ДАТЫ -->
          <v-col :cols="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="forMainDialog.promocode.date_limit"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="mt-0 pt-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="forMainDialog.promocode.date_limit"
                  label="Лимит даты"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  outlined
                  hide-details
                  clearable
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="forMainDialog.promocode.date_limit"
                no-title
                scrollable
                color="primary"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(forMainDialog.promocode.date_limit)"
                >
                  Применить
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Лимит кол-ва -->
          <v-col :cols="12">
            <v-text-field
              :value="forMainDialog.promocode.quantity_limit"
              label="Лимит кол-ва"
              dense
              outlined
              :spellcheck="false"
              hide-details
              type="number"
              @input="setQuantityLimit"
            />
          </v-col>
          <v-col
            :cols="12"
            class="not-full-switch"
          >
            <v-switch
               v-model="forMainDialog.promocode.ignoreUsedPromo "
               style="display: block"
              label="Активировать для старых пользователей"
              dense
              hide-details
              class="mt-0"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          :disabled="disablePosting"
          @click="forMainDialog.dialog === 'add' ? addPromo() : editPromo()"
        >
          {{ forMainDialog.dialog === 'add' ? 'Добавить' : 'Сохранить' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PromocodesMainDialog',

  data() {
    return {
      menu: false,
      loading: false,
      rules: {
        discount: [
          (value) => !value || (value >= 1 && value < 99) || 'Размер скидки должен быть от 1 до 99',
        ],
      },
    };
  },

  computed: {
    ...mapGetters({ dialogs: 'promocodes/dialogs' }),
    ...mapFields('promocodes', ['forMainDialog']),

    disablePosting() {
      const { promocode } = this.forMainDialog;
      return this.$_.isEmpty(promocode.code) || (promocode.discount < 1 || promocode.discount > 99);
    },
  },

  beforeDestroy() {
    this.$store.dispatch('promocodes/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('promocodes/closeDialog', 'mainDialog');
    },

    setQuantityLimit(value) {
      if (value === '') {
        this.forMainDialog.promocode.quantity_limit = null;
      } else {
        this.forMainDialog.promocode.quantity_limit = value;
      }
    },

    async addPromo() {
      try {
        this.loading = true;

        const promocode = {
          code: this.forMainDialog.promocode.code.toString().toUpperCase(),
          email: this.forMainDialog.promocode.email,
          discount: this.forMainDialog.promocode.discount,
          dateLimit: this.forMainDialog.promocode.date_limit,
          quantityLimit: this.forMainDialog.promocode.quantity_limit,
          ignoreUsedPromo: this.forMainDialog.promocode.ignoreUsedPromo,
        };

        const response = await this.api.post('/admin/promo', promocode);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('promocodes/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },

    async editPromo() {
      try {
        this.loading = true;
        const promocode = {
          code: this.forMainDialog.promocode.code.toString().toUpperCase(),
          email: this.forMainDialog.promocode.email,
          discount: this.forMainDialog.promocode.discount,
          dateLimit: this.forMainDialog.promocode.date_limit,
          quantityLimit: this.forMainDialog.promocode.quantity_limit,
          ignoreUsedPromo: this.forMainDialog.promocode.ignoreUsedPromo,
        };

        const response = await this.api.patch(`/admin/promo/${this.forMainDialog.promocode.id}`, promocode);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('promocodes/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
<style>
.not-full-switch .v-input__slot {
  display: block !important;
}
.not-full-switch .v-input__slot:hover {
  cursor: default !important;
}
</style>
